import React from "react";
import cn from "classnames";

import FooterNav from "../FooterNav";

import styles from "./styles.module.scss";

const Footer = () => (
  <footer className={styles.footer}>
    <div className="container">
      <p className={cn("color-grey type-16", styles.footerText)}>
        <strong>Notes:</strong>
        <br/>
        The data powering Totaljobs’ Career Change Tracker and resulting statistics listed above come from a
        StepStone-developed data source derived from proprietary candidate information pulled from anonymised online
        profiles and CVs held across Totaljobs Group Limited and StepStone Group, in addition to external sources. We
        use data points such as a candidate’s discipline, job title, tenure, years of experience and previous employer
        to compile our data source (“Data Points”). This structured data is company centric (i.e. we use all the data
        about a company’s employees, rather than obtaining specific Data Points) and has been aggregated for this report
        by company industry and job discipline.
        <br/>
        <br/>
        We’re constantly evolving our approach to candidate data, allowing us to build an increasingly robust sample
        size over time. This is why records from 2015, 2016 and 2017 may appear limited compared to recent years. The
        sample for 2022 is temporarily limited due to the time taken for CVs to be updated after changing jobs. Some
        figures may not add up due to rounding.
      </p>

      <FooterNav/>
    </div>
  </footer>
);

export default Footer;
