import React from "react";

import Header from "../components/Header";
import Layout from "../components/Layout";
import Footer from "../components/Footer";

const NotFoundPage = () => (
  <Layout>
    <Header>
      <h1 className="py-4 my-0">Page Not Found</h1>
    </Header>

    <Footer />
  </Layout>
);

export default NotFoundPage;
